.card.tall {
  overflow: hidden;
  min-height: 390px;
}

.empty-data-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-tabs-nav .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.ownership-dist {
  display: flex;
  .card.tall {
    flex: 1;
  }

  .apexcharts-canvas {
    padding-top: 20px;
  }
}

.baseline {
  align-items: baseline;
}

.react-datepicker__input-container input::placeholder {
  color: rgb(51, 65, 85) !important;
}

.flex-1 {
  flex: 1;
}

.nxl-header .header-wrapper .user-avtar {
  width: 40px;
  margin-right: 15px;
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
}

.ResetPassword {
  max-width: 500px !important;
}

[dir="ltr"]:first-of-type ~ [dir="ltr"] {
  display: none;
}

[dir="ltr"]:first-of-type ~ br,
[dir="ltr"]:first-of-type ~ div {
  display: none;
}

[dir="ltr"]:first-of-type br:last-of-type {
  display: none;
}

.gmail_quote,
.gmail_signature,
.gmail_signature_prefix {
  display: none !important;
}
.nxl-container .nxl-content {
  .fixed-management {
    .page-header,
    .tab-menu {
      position: fixed;
      width: 100%;
    }

    .main-content {
      padding-top: 155px;

      &.p-0 {
        padding-top: 130px !important;

        &.contact {
          padding-top: 65px !important;
        }

        &.document {
          padding-top: 65px !important;
        }
      }

      @media (max-width: 767px) {
        padding-top: 90px;
      }
    }
  }
}

@media (min-width: 768px) {
  .scrolling {
    .page-header {
      min-height: 35px;

      .page-header-title {
        font-size: 15px;
      }
    }

    .tab-menu {
      top: 115px;

      .nav-tabs-wrapper {
        height: 35px;
      }
    }

    .page-header-right-items {
      .btn {
        padding: 6px 16px;
      }
    }
  }
}

.nxl-header-search {
  .dropdown-menu {
    width: 375px;
  }
}

.status.Not.Invested span {
  background: blue;
}

.status.Raising.Capital span {
  background: orange;
}

.status.Committed span {
  background: orange !important;
}

.status.Not.Invested span {
  background: grey !important;
}

.nav-tabs-wrapper .nav-tabs-custom-style .nav-item .nav-link {
  height: 100%;
  align-items: center;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}

.nxl-header
  .header-wrapper
  .nxl-notifications-menu
  .notifications-item
  .notifications-date
  time {
  font-size: 11px;
}

.nxl-header .header-wrapper ul {
  list-style: none;
  gap: 35px;

  .nxl-item {
    &.active {
      .nxl-mtext {
        border-bottom: 2px #000 solid;
        padding-bottom: 3px;
      }
    }
  }

  .management-link {
    border-left: 1px solid #000;
    padding-left: 25px;

    .nxl-mtext {
      font-weight: bold;
    }
  }
}

.notifications-item {
  &:hover {
    background: #f2f2f2;
  }

  a {
    width: 100%;

    .notification-text {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

html.app-skin-dark,
html.app-skin-light {
  .sweetalert-overflow-hidden {
    .tab-menu,
    .nxl-header {
      z-index: 9;
    }

    .sweet-alert {
      h2,
      div {
        color: #000 !important;
      }

      .btn-link {
        background: #eee;
        color: #000 !important;

        &:hover {
          color: #000;
        }
      }
    }
  }
}

.white-text {
  color: #fff;

  i {
    color: #fff;
  }
}

.dropdown-menu {
  .search-form {
    margin-left: auto;
    max-width: 100%;
    overflow: hidden;
    padding: 0 5px;
    width: auto;
  }
}

.nxl-navigation .navbar-content .nxl-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .inner-div {
    display: flex;
    align-items: center;
    width: 100%;

    .nxl-arrow {
      margin-left: auto;
    }
  }
}

.side-bar-parent {
  display: flex;
  flex-direction: column;

  .createEntity-ul {
    margin-top: auto;
  }
}

.doc-message {
  background: #fff;
  padding: 60px;
  border-radius: 20px;
  text-align: center;
  position: relative;

  button {
    position: absolute;
    top: 10px;
    right: 10px;

    i {
      color: #334155 !important;
    }
  }

  a {
    border: none;
    background: #102239;
    color: #fff;
    padding: 12px 40px;
    border-radius: 5px;
    font-size: 16px;
  }
}

#total-sales-color-graph {
  position: relative;
  .apexcharts-canvas {
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }
}

.flex-column {
  flex-direction: column;
}

@media (min-width: 769px) {
  .invite .customers-nav-tabs {
    flex-direction: row;
  }
}

.card.dragging {
  #dragOverlay {
    display: flex;
  }
}
#dragOverlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 128, 1, 0.2);
  z-index: 9;
  align-items: center;
  justify-content: center;
  color: #066316;
  text-align: center;

  i {
    font-size: 64px;
    margin-bottom: 20px;
  }
  .main-text {
    font-size: 30px;
    text-align: center;
    margin: 0;
  }
  p {
    font-size: 20px;
    span {
      font-weight: bold;
    }
  }
}
.ov-auto {
  overflow: auto;
}
.profile-section {
  padding-top: 65px;
}

.table-options {
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.table-options.animate-show {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  overflow: visible;
}

.main-content.contact .content-sidebar-items {
  overflow: auto;
  height: calc(100vh - 276px);
}

.main-content.document {
  height: calc(100vh - 81px);
  .content-sidebar-items {
    overflow: auto;
    height: calc(100vh - 146px);
  }
}
.isAdmin .investor-inner-row {
  max-height: calc(100vh - 146px);
}
.investor-inner-row {
  overflow: auto;
  padding-top: 84px;
  padding: 84px 20px 0;
}

.isInvestor {
  .main-area {
    width: 100%;
  }
}

.main-area {
  width: calc(100vw - 320px);
}
