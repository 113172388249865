.col-md-6.textarea {
	width: 100%;
}

.single-task-list {
	&.task-completed {
		color: #17c666 !important;
		background-color: rgba(23, 198, 102, 0.075);
		text-decoration: line-through !important;
	}
}

.task-description {
	.text-truncate-1-line {
		width: calc(100% - 161px);
		padding-right: 7px;
	}
}
