#zingchart-react-0-license-text {
  display: none;
}

.property-type-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.types-box {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.empty-data-message {
  max-width: 300px;
  margin: 30px auto;
  text-align: center;
}

.property-type-item {
  height: 15px;
  width: 15px;
  border-radius: 100px;
  display: flex;
  margin-right: 6px;
  margin-left: 20px;
}

.EstimatedEstimatedInvestmentValuePropertyValue {
  .vert-center {
    padding: 0 0 30px 0;
  }

  .large-stat-text {
    margin-top: 0;
  }
}

.has-footer {
  .rdt_TableBody {
    display: flex;
    margin-top: 40px;

    .rdt_TableRow {
      min-height: 60px;

      &:last-child {
        position: absolute;
        top: 40px;
        background: #374d6c;
        min-height: 40px;
        padding: 0 10px;
        max-height: 40px !important;
        min-height: 40px !important;
      }
    }

    .rdt_ExpanderRow {
      .rdt_TableBody {
        display: flex;
        margin-top: 0px;

        .rdt_TableRow {
          min-height: 60px;

          &:last-child {
            position: relative;
            top: 0px;
            background: transparent;
            padding: 14px 10px;
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.FinancialsTableBox {
  .rdt_TableBody {
    display: flex;
    margin-top: 0px;

    .rdt_TableRow {
      min-height: 60px;
    }

    .rdt_ExpanderRow {
      .rdt_TableBody {
        display: flex;
        margin-top: 0px;

        .rdt_TableRow {
          min-height: 60px;

          &:last-child {
            position: relative;
            top: 0px;
            background: transparent;
            padding: 14px 10px;
          }
        }
      }
    }
  }
}

.rdt_TableRow {
  border-bottom: 1px #484b5c solid;
  //overflow: hidden;
  padding: 0px 10px;
  min-height: 60px !important;

  svg {
    fill: #fff;
  }
}

.investment-slider {
  .slick-track {
    min-width: 100%;
  }

  .investment-box {
    min-width: 380px;
    max-width: 380px;
  }

  .property-box-main {
    min-width: 380px;
    max-width: 380px;
  }
}

.margin-0 {
  margin: 0;
}

.flex {
  display: flex;
}

.help-tip {
  position: relative;
  top: 50%;
  left: 4px;
  /* transform: translate(-50%, -50%); */
  margin: auto;
  text-align: center;
  border: 1px solid #8a99b5;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 13px;
  line-height: 8px;
  cursor: default;
}

.help-tip-icon {
  border: none;
  padding: 0;
  background: transparent;
}

.help-tip-icon p {
  display: none;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  background-color: #ffffff;
  padding: 4px 16px;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 3px;
  /* border: 1px solid #E0E0E0; */
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393d;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
  white-space: nowrap;
  top: -120%;
}

.help-tip:before {
  content: "?";
  font-family: sans-serif;
  font-weight: normal;
  color: #8a99b5;
  font-size: 8px;
}

.help-tip-icon:hover p,
.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}

/* The tooltip */
.help-tip p {
  display: none;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  background-color: #ffffff;
  padding: 12px 16px;
  width: 178px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 3px;
  /*  border: 1px solid #E0E0E0; */
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393d;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
}

.help-tip-icon p a,
.help-tip p a {
  color: #067df7;
  text-decoration: none;
}

.help-tip-icon p a:hover,
.help-tip p a:hover {
  text-decoration: underline;
}

/* The pointer of the tooltip */

.help-tip p:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  top: -9px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.help-tip-icon p:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

/* Prevents the tooltip from being hidden */
.help-tip-icon p:after,
.help-tip p:after {
  width: 10px;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.help-tip-icon p:after {
  width: 10px;
  height: 40px;
  content: "";
  position: absolute;
  bottom: -40px;
  left: 0;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

#modalMaster .form-control.edit-box .inactive {
  pointer-events: none;
  background: transparent;
  border: none;
  font-weight: bold;
}

.fpYUKa {
  overflow: auto;
}

.rdt_TableHeadRow {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  font-size: 12px;
  font-weight: 600;
  color: rgb(189, 209, 248);
  padding-left: 10px;
  padding-right: 15px;
  background-color: rgb(30, 49, 73);
  min-height: 34px;
}

.sortableRow {
  margin: 15px 0;

  label {
    display: block;
  }
}

.small-btn {
  border-radius: 4px;
  border: solid 0.7px #76a4c7;
  background: #24456c;
  outline: none;
  color: #cee0ee;
  margin: 5px;
  font-size: 12px;
}

.fixed .content-wrapper {
  padding-top: 100px;
}

.Image {
  width: 100%;
  text-align: center;

  img {
    width: 80%;
    margin: 0 auto;
    height: auto;
  }
}

.invite-image {
  .Image {
    width: 100%;
    text-align: center;

    img {
      width: 100%;
      margin: 0 auto;
      height: 300px;
      object-fit: cover;
    }
  }
}

.flex-d-column {
  display: flex;
  flex-direction: column;
}

.editable-column-box {
  .form-group {
    margin-bottom: 0.5rem;
  }
}

.red-text {
  margin: 0;
  font-size: 18px;
  color: red;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.select-box__option {
  color: #112239;
}

.edit-box {
  background: transparent;
  color: #ffff;
  border: none;
  border-bottom: 1px solid #1a334b;
  border-radius: 4px;
  border: solid 1px #76a4c7;
  background: #24456c;
  outline: none;
  color: #cee0ee;
  margin: 5px;
  font-size: 12px;
}

.DynamicSearch {
  min-width: 142px;
}

.eTvlwW {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: visible auto;
  min-height: 0px;
}

.css-yk16xz-control {
  border-color: #121725;
  color: #fff;
  background: #1a233a;
}

.slick-track {
  .box {
    padding-top: 1.5rem;
  }

  .card {
    margin-bottom: 0;
  }
}

.buttonBox {
  height: auto;

  button {
    margin-left: 5px;
  }
}

.h-auto {
  height: auto;
}

.confirmation-text {
  font-size: 23px;
  text-align: center;
}

.box {
  background: transparent;
  border: 2px #272e48 solid;

  .box-header {
    // border-bottom:2px #272e48 solid ;
  }

  .list-content {
    border: 2px #272e48 solid;
  }
}

.btn-primary {
  box-shadow: none;
}

.status {
  display: flex;
  align-items: center;

  .dot {
    height: 13px;
    width: 13px;
    display: inline-flex;
    background: red;
    border-radius: 100px;
    margin-right: 4px;
  }

  &.Invested,
  &.Active,
  &.Complete {
    .dot {
      background: green;
    }
  }
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 10px;
    font-size: 1.5rem;
  }
}

.align-right {
  margin-left: auto;
}

.flex {
  display: flex;
}

.center-align {
  align-items: center;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: relative;
  left: 0;
  opacity: 1;
}

.image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.file-title {
  margin: 25px 0 0 0;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sidebar-menu li.nav-main-item a,
.sidebar-menu li.nav-main-item button {
  display: flex;
}

.upload-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.file-upload {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.message-count {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bdd1f8;
  border-radius: 130px;
  color: #212529;
  height: 25px;
  margin-left: auto;
  padding: 5px 10px;
  text-align: center;
  width: 25px;
}

.btn-blue {
  background-color: #71d2fc;
  color: #1a233a;
  border-color: #71d2fc;
}

.b-border {
  border-bottom: 1px #5151516e solid;
  border-radius: 0;
}

.text-blue span {
  color: #8a99b5;
}

.login-bg {
  min-height: 100vh;
  height: 100%;
  background: rgba(41, 50, 76, 0.95);

  .login-footer {
    margin: auto auto 10px;
    text-align: center;

    p {
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
    }
  }

  .col-12 {
    margin-top: 50px;
  }

  .logo {
    width: 250px;
  }

  .text-white-50 {
    font-size: 30px;
    text-transform: capiwtalize;
    margin-top: 23px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 200;
  }

  .btn-primary {
    background-color: #71d2fc;
    color: rgba(26, 35, 58, 1);
    border-color: #71d2fc;

    &:hover {
      background-color: rgba(26, 35, 58, 1);
      border-color: rgba(26, 35, 58, 1);
      color: #fff;
    }
  }

  .auth-box {
    max-width: 360px;
    margin: 0 auto;
  }
}

#sidebar {
  overflow: auto;
}

.stacked-parent {
  height: 100%;
  display: flex;
  flex-direction: column;

  .box {
    justify-content: center;
  }
}

.z-999 {
  z-index: 999;
}

#mapBox {
  height: calc(100% - 65px);
  box-sizing: border-box;
  min-height: 500px;
}

.p-0 {
  padding: 0;
}

.property-mix-stats {
  .stat-label {
    font-size: 20px;
    font-weight: bold;
  }
}

.header-title {
  border-bottom: 1px solid;
  padding: 5px 0;
  font-size: 17px;
  margin-bottom: 10px;
  color: #8a99b5;
  border-color: rgba(255, 255, 255, 0.12);
}

.float-right {
  float: right;
}

.small-cap-rate {
  font-size: 20px;
  color: #8a99b5;
  width: 100px;
  outline: none;
  cursor: pointer;

  .small-cap-rate__control {
    outline: none;
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .small-cap-rate__menu {
    background: #fff;
    padding: 0;
    border: none;
  }

  .small-cap-rate__single-value {
    font-size: 20px;
    color: #8a99b5;
  }

  .small-cap-rate__indicator-separator {
    display: none;
  }

  .small-cap-rate__option {
    padding: 0;
  }
}

.investment-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.active {
  .investment-overlay {
    background: rgba(0, 0, 0, 0.4);
  }
}

.property-box {
  &:hover {
    .card {
      border-color: #fff;
    }
  }

  .top-area {
    text-align: center;
    margin-bottom: 20px;
  }

  .bottom {
    position: relative;
    z-index: 99;

    span {
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }
  }

  .location {
    font-weight: 700;
    font-size: 16px;
  }

  h4 {
    color: #fff;
  }

  .row {
    //padding: 0 40px;
    text-align: center;

    .col-6 {
      padding-bottom: 5px;
      padding-top: 5px;

      &.border-bottom {
        border-bottom: 1px solid;
        border-color: rgba(255, 255, 255, 0.12);
      }

      &.border-right {
        border-right: 1px solid;
        border-color: rgba(255, 255, 255, 0.12);
      }

      span {
        color: #bdd1f8;

        span {
          display: block;
        }
      }
    }
  }
}

.investment-box.property-box {
  .card {
    border: 4px solid #fff;
    border-radius: 10px;
  }

  &.active {
    .card {
      border: 4px solid #67e6ff;
    }
  }
}

.small-property-box {
  height: 100px;
  width: 100px;
}

.stat-row {
  position: relative;
}

.stat-row:after {
  content: "";
  height: 35%;
  width: 1px;
  background-color: #3f5d8c;
  position: absolute;
  right: -5px;
  top: 28%;
}

.stat-row:last-child:after {
  display: none;
}

.map-detail {
  height: 200px;
  width: 200px;
}

#map {
  margin: auto;
  width: 100%;
  height: 400px;
  text-align: center;

  path {
    fill: #3f5d8c;
  }
}

.box.tall {
  min-height: 390px;
}

.rdt_Pagination {
  svg {
    fill: #bdd1f8;
  }

  [disabled] {
    svg {
      opacity: 0.4;
    }
  }
}

[role="columnheader"] {
  color: #76a4c7;
  font-size: 16px;
  // &:hover {
  //     color: rgba(189, 209, 248, 0.8) ;
  //     cursor: pointer ;
  // }
}

table time,
time {
  font-size: 1rem;
  font-weight: 400;
}

.list-content {
  time {
    // color: rgb(189, 209, 248);
    // font-weight: 400;
    // font-size: 16px;
  }
}

.box-header .box-title {
  padding: 7px 0;
  font-size: 14px;
  color: #858f9e;
  text-transform: uppercase;
}

.section-title-box {
  padding: 0;
  margin-bottom: 15px;

  .box-title {
    font-weight: bold;
    padding-bottom: 5px;
  }
}

.progress-parent {
  display: flex;
  align-items: center;
  padding: 0 50px;
  width: 100%;
  justify-items: flex-start;
  padding-left: 0;

  .progress-label {
    display: inline-block;
    float: left;
    margin-right: 0;
    width: 60px;
    text-align: left;
    white-space: nowrap;
  }

  .progress {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.table .progress {
  margin-bottom: 0;
}

.apexcharts-xaxis-label tspan,
.apexcharts-text tspan {
  fill: #bdd1f8;
}

.apexcharts-legend-text {
  color: #bdd1f8;
}

.property-detail-title time {
  font-size: 18px;
}

.title-span {
  display: inline-block;
  color: #fff;
  //width: 100%;
}

.nav-main-link {
  svg {
    margin-right: 14px;
    width: 30px;

    path {
      fill: $dark-text;
    }
  }
}

.info-span {
  float: right;
  font-size: 14px;
  text-align: right;
  display: inline-block;

  time {
    margin-bottom: 9px;
    font-size: 14px;
  }
}

.property-box {
  padding: 0 15px;

  .box {
    margin-bottom: 0;
  }

  &.active {
    .card {
      background: #8a99b5;
      border-color: #8a99b5;

      .bottom {
        color: #fff;
      }

      h4 {
        color: #fff;
      }
    }
  }
}

.property-box-small {
  padding: 0 15px;

  .box {
    margin-bottom: 0;
  }

  &:hover {
    .box {
      border-color: #fff;
    }
  }

  &.active {
    .box {
      background: #2b3d5d;
      border-color: #fff;
    }
  }
}

.inline-block {
  display: inline-block;
}

.prop-investment-slider .slick-slide {
  min-width: 690px;
  margin-right: 15px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.prop-slider .slick-slide {
  min-width: 390px;
  margin-right: 15px;

  .img {
    height: 200px;
    width: 100%;
  }
}

.no-carousel {
  .slick-slider {
    margin: 0 -15px;
  }
}

.fixed .app .main-header {
  z-index: 99999;
}

.main-header li.user-header {
  background-color: #232d4c;
  padding: 10px 15px 0;
}

.property-image {
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.carousel {
  .slick-slider {
    width: 98%;
    margin: 0 1%;
  }
}

.card {
  .bottom {
    padding: 10px;
    overflow: hidden;
    text-align: left;

    h4 {
      white-space: nowrap;
      text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.horizontal-graph {
  display: flex;
  width: 100%;
}

.key-color {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.vc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.key-items {
  display: flex;

  .key-item {
    justify-content: flex-start;
    display: flex;
    padding: 10px 30px 10px 0px;
    color: #bdd1f8;
    font-size: 16px;
    flex-direction: column;
    text-align: left;

    .top {
      display: flex;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}

.bar-item {
  display: inline-block;
  float: left;
  height: 45px;
  color: #fff;
  margin-top: 10px;
}

.eclipsed-text {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 12px;
}

.property-mix {
  .apexcharts-legend.position-left {
    left: 0;
  }

  .apexcharts-legend-series {
    text-align: left;
  }
}

.vert-center {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.date-box {
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  font-style: italic;
}

.large-stat-text {
  font-size: 50px;
  margin-top: -100px;
  text-align: center;
  color: #37c4fc;
}

.ms-20 {
  margin: 0 20px;
}

.stat-label {
  font-size: 22px;
  margin-bottom: 0;
}

.stat-text {
  font-size: 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.click-area {
  cursor: pointer;
}

.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 75px;
  }
}

.invite-img {
  width: 100%;
}

.br-0 {
  border-radius: 0;
}

.property-detail-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.p-l-0 {
  padding-left: 0;
}

.box-body,
.form-body {
  &.minimize {
    height: 0;
    overflow: hidden;
    padding: 0;
    display: none;
  }
}

.collapse-link {
  .fa {
    transform: rotate(180deg);
  }

  &.minimize {
    .fa {
      transform: rotate(0deg);
    }
  }
}

.select-box__control {
  .select-box__single-value {
    color: #fff;
  }
}

.select-box__control--menu-is-open,
.select-box__control--is-focused {
  .select-box__single-value {
    color: #000;
  }
}

.lower-btn-area {
  background: #fff;
  overflow: hidden;
  border-top: 1px #eee solid;
  padding-right: 0;
}

.location-search-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #575757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.dropzone .dz-message {
  margin: 5rem 0;
  text-align: center;
}

.margin-0 {
  margin: 0;
}

.fw {
  width: 100%;
}

.vh-box {
  height: calc(100vh - 90px);
  overflow: auto;
}

.doc-list {
  img {
    margin-bottom: 20px;
    border: 1px #d2d0d0 solid;
  }
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.tr-date {
  background: #112134;
  color: #fff;
}

.mt-auto {
  margin-top: auto;
  background-color: transparent;
  margin: 0;
  padding: 10px;

  button {
    margin-left: 5px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.tr-noi,
.tr-cashflow,
.tr-free_cash_flow {
  background: #69859e;
  color: #fff;

  .title-noi {
    text-transform: uppercase;
  }

  .title {
    text-align: left;
    padding-left: 50px;
  }
}

.doc-section {
  img {
    width: 100%;
    margin-bottom: 20px;
  }
}

.left_col .left_col {
  height: 100%;
  overflow: auto;
  height: 100vh;
  width: 230px;
  padding: 0;
  position: fixed;
  /* display: flex; */
  z-index: 1;
  /* overflow: auto; */
  top: 0;
  left: 0;
}

.float-left {
  float: left;
}

.oh {
  overflow: hidden;
}

.button-box {
  float: right;
  padding: 5px;
  display: flex;
}

.logo {
  padding: 0;

  svg {
    width: 150px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;

  .list-item {
    display: flex;

    .list-content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.main_menu .fas {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  .block-title {
    float: left;
  }

  .navbar-right {
    float: right;
    line-height: 49px;
  }
}

.content-header {
  .mr-auto {
    align-items: center;
    display: flex;
    padding: 10px 0;
  }
}

.theme-primary .sidebar-menu > li {
  a,
  button {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &.active a {
    background-color: #152b48;
    border-radius: 5px;
  }
}

.four-boxes {
  font-size: 20px;
  text-align: center;

  span {
    font-size: 20px;
    font-weight: 600;
  }

  h6 {
    margin-bottom: 0;
  }

  .box {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .stat-text {
    color: #3dccff;
    font-size: 40px;
  }
}

.theme-primary .sidebar-menu > li.active a > i,
.theme-primary .sidebar-menu > li.active a .nav-main-link-name {
  color: $dark-text;
}

.tab-area {
  margin-bottom: 10px;
  display: flex;
}

.no-pa dding-box {
  padding: 0;
}

.page-header-title {
  margin-bottom: 0;
}

.box-header.management-header {
  color: #8a99b5;
  background: #182f4f;
  padding: 0;
  border-radius: 00;
  padding: 2px 20px;
  margin-bottom: 15px;
  margin-top: 40px;
  display: flex;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cee0ee;
    opacity: 1;
    /* Firefox */
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cee0ee;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #cee0ee;
    font-weight: 400;
  }
}

.header-input {
  height: 30px;
  padding: 4.8px 412px 4px 5px;
  opacity: 0.4;
  border-radius: 4px;
  border: solid 0.7px #76a4c7;
  background: #24456c;
  outline: none;
  color: #cee0ee;
  padding: 3px 10px 3px 30px;
  letter-spacing: 0.03rem;
  font-size: 16px;
  background-image: url("../img/search.png");
  background-repeat: no-repeat;
  background-position: 6px;
  background-size: 18px;
}

.management-light-bg {
  .box {
    border: 0;
  }
}

.new-border-bottom {
  border-bottom: 2px #272e48 solid;
}

.rdt_TableHeadRow {
  min-height: 40px;

  .jIwqLP {
    background: rgb(30, 49, 73);
  }
}

.css-1uccc91-singleValue {
  color: #fff;
}

.select-box__control--menu-is-open,
.select-box__control--is-focused,
input.form-control {
  border-color: #121725;

  .select-box__single-value,
  .select-box__single-value {
  }
}

.table .rdt_ExpanderRow {
  .select-box__control--menu-is-open,
  .select-box__control--is-focused,
  input.form-control {
    border-color: #76a4c7;

    .select-box__single-value,
    .select-box__single-value {
    }
  }
}

[role="columnheader"] {
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  color: #76a4c7;
}

.rdt_TableCell {
  //padding-left: 0 ;
  // min-width: 200px ;
}

.rdt_TableCell a:hover {
  color: #fff;
}

.property-mix-stats {
  .rdt_TableCell,
  .rdt_TableCell time,
  ,
  .rdt_TableCell span {
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    white-space: nowrap;
  }
}

.rdt_TableCell,
.rdt_TableCell time {
  font-size: 14px;
  color: #ffffff;
  text-align: left;

  button {
    background: transparent;
    font-weight: bold;
    text-align: left;
  }

  .select-box,
  .ContactSearchBox {
    width: 100%;
  }
}

.rdt_TableCol {
  // padding-left: 0 ;
}

.nested {
  .box {
    border: 1px transparent solid;
    margin-bottom: 0;
  }
}

.rdt_ExpanderRow {
  padding-left: 49px;
  margin-bottom: 0px;

  .rdt_TableHead {
    display: none;
  }

  .showNestedHeader .rdt_TableHead {
    display: block;
  }

  // .rdt_TableCol{
  //     background: rgba(0,0,0,.4) ;
  //     color: rgb(30, 49, 73) ;
  // }

  .rdt_TableBody {
    .rdt_TableCell,
    .rdt_TableCell time {
      color: #76a4c7;
    }
  }
}

.no-height-limit {
  .rdt_TableBody {
    max-height: 9999px;
  }
}

.fRfgft {
  svg {
    fill: #fff;
  }
}

.flex-grow {
  flex-grow: 1;

  .btn {
    float: right;
    background: transparent;
    border: 0;

    &:hover {
      background: transparent;
    }

    i {
      color: #76a4c7;
    }
  }
}

.box-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.management-tab {
  background: #182f4f;
  color: #eee;
  border: none;
  width: 16.666667%;
  line-height: 40px;
  text-align: left;
  outline: none;
  font-size: 20px;
  padding: 10px 0px 20px;

  &:first-child {
    padding-left: 20px;

    .inner {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  &:last-child {
    padding-right: 20px;

    .inner {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .inner {
    border-bottom: 9px solid #264976;
    position: relative;
    line-height: 58px;

    span {
      height: 9px;
      left: 0;
      position: absolute;
      bottom: 0;
      width: calc(100% - 40px);
    }
  }

  &:hover {
    color: #fff;
  }

  &.active {
    .inner {
      span {
        position: absolute;
        bottom: -9px;
        width: calc(100% - 40px);
        background: #50e3c2;
        border-radius: 5px;
      }
    }

    &:last-child {
      .inner {
        span {
          width: 100%;
        }
      }
    }
  }
}

.theme-primary .sidebar-menu > li {
  ul {
    display: none;
  }

  &.active {
    ul {
      display: block;
      padding: 0;

      a,
      button {
        padding-left: 10px;
      }
    }

    li {
      ul {
        display: none;
      }

      &.active {
        ul {
          display: block;
          padding: 0;

          a,
          button {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.pw-20 {
  padding: 0 20px;
}

.justify-center {
  justify-content: center;
}

.sub-nav-item {
  span {
    font-size: 12px;
  }

  &.active {
    span {
      color: #fff;
    }
  }

  .sub-nav-item {
    span {
      font-size: 12px;
      color: #8a99b5;
    }

    &.active {
      span {
        color: #566f9e;
      }
    }
  }
}

.small-stat-box {
  .title {
    text-align: left;
    margin-bottom: 0;

    span {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }

  .small-stat-text {
    justify-content: flex-start;
    font-size: 34px;
    margin-bottom: 5px;
    line-height: 100%;

    span {
      font-size: 14px;
    }
  }
}

.ownership-dist {
  .apexcharts-legend.apx-legend-position-bottom,
  .apexcharts-legend.apx-legend-position-top {
    flex-wrap: inherit;
    flex-direction: column;
    align-items: center;
  }
}

.editable-column-box {
  .css-1hwfws3 {
    height: 20px;
  }

  .select-box {
    max-width: 134px;
    min-width: 134px;
    margin: 0;
    float: right;
  }

  .select-box__placeholder,
  .select-box__single-value {
    position: absolute;
    top: 0;
    transform: none;
    display: flex;
    justify-self: center;
    height: 100%;
    line-height: 21px;
  }

  .select-box__single-value {
    color: #cee0ee;
  }

  .select-box__control {
    border-color: #121725;
    color: #fff;
    /* background: #1a233a ; */
    border-radius: 4px;
    border: solid 0.7px #76a4c7;
    background: #24456c;
    outline: none;
    color: #cee0ee;
    margin: 5px;
    font-size: 12px;
    min-height: 24px;
    max-height: 20px;

    .select-box__value-container {
      /* -webkit-box-align: center; */
      /* align-items: center; */
      display: inline-block;
      /* flex: 1 1 0%; */
      /* flex-wrap: wrap; */
      padding: 2px 8px;
      position: relative;
      overflow: visible;
      box-sizing: border-box;
      width: auto;
    }
  }

  .select-box__indicator {
    padding: 0 8px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.flex-direction-column {
  flex-direction: column;
}

.fill-height {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .box {
    height: auto;
  }
}

.nxl-navigation .navbar-content {
  width: 250px;
}

.management-button-box {
  display: flex;
  margin-left: auto;

  button {
    margin-left: 7px;
  }
}

.delete-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  p {
    margin: 0;
    margin-right: 10px;
  }
}

.localized-table {
  overflow: auto;

  tfoot {
    font-weight: bold;
    margin-top: 13px;
  }

  table {
    width: 100%;
    text-align: left;

    tbody {
      overflow: auto;
    }
  }

  th {
    color: #76a4c7;
    padding-left: 15px;
    padding-right: 15px;
    background: rgb(30, 49, 73);
    min-height: 34px;
    font-weight: 400;
    padding: 10px 10px;
  }

  tr td {
    border-bottom: 1px #484b5c solid;
    padding: 9px 10px;
  }
}

.property-info {
  .box-header.image-area {
    padding: 10px 20px;

    .box-title {
      font-size: 18px;

      .subtitle {
        font-size: 14px;
      }
    }
  }

  .box-body {
    margin-top: 0;
  }
}

.flex-right {
  justify-content: flex-end;
}

.nxl-header .header-wrapper .nxl-h-dropdown {
  max-width: 485px;
  width: 100%;
  top: 100%;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 1200px) {
  .hide-on-desktop {
    display: block;
  }

  .m-header svg {
    height: 50px;
  }

  .m-header {
    justify-content: space-around;
  }

  .minimenu-mobile .nxl-navigation {
    left: 0 !important;
  }

  .card.tall {
    background: #fff;
    border: none;
    min-height: 400px;
  }

  .nxl-navigation {
    left: -250px;
    box-shadow: none;
    transition: all 0.15s ease-in-out;
  }

  .nxl-header {
    top: 0;
    left: 0 !important;
    transition: all 0.15s ease-in-out;
  }
}

@media (max-width: 768px) {
  .field-content-area {
    text-align: left;
  }
}

.field-content-area .status {
  justify-content: flex-end;
}

.switch {
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.nxl-container .nxl-content .apps-container .main-content {
  overflow-x: hidden;
  padding: 30px 30px 5px;
  height: calc(100vh - 80px);

  .content-sidebar,
  .content-area {
    height: 100%;

    .content-area-body {
      max-height: 100vh !important;
      height: calc(100vh - 215px) !important;
    }
  }

  .content-sidebar-body {
    overflow: auto;
    height: calc(100% - 75px);
  }
}

.select-box__value-container * {
  border-radius: 5px;
}

.messages .css-yk16xz-control {
  min-height: 68px !important;
  border-color: transparent !important;
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;

  .select-box__value-container {
    min-height: 68px !important;
    border-color: transparent !important;
    border-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
  }
}

$success__primary: #3dc480;
$success__secondary: #b8babb;

.success-message {
  text-align: center;
  max-width: 500px;
}

.success-message__icon {
  max-width: 75px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.success-message__title {
  color: $success__primary;
  transition: all 200ms ease;
  transform: translateY(0);
  opacity: 1;
}

.success-message__content {
  color: $success__secondary;
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms ease;
  transition-delay: 50ms;
}

.icon-checkmark {
  circle {
    fill: $success__primary;
    transform-origin: 50% 50%;
    transform: scale(0);
    transition: transform 200ms cubic-bezier(0.22, 0.96, 0.38, 0.98);
  }

  path {
    fill: $success__primary;
    transition: stroke-dashoffset 350ms ease;
    transition-delay: 100ms;
  }

  .active & {
    circle {
      transform: scale(1);
    }
  }
}
