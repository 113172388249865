.field-column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .form-group {
    display: flex;
    align-items: center;

    label {
      margin-right: auto;
      margin-bottom: 0;
    }

    .default-value {
      margin: 0;
    }
  }
}

[name="investment_description"],
[name="description_property"],
[name="description"] {
  min-height: 300px !important;
}

.dynamic-form-box {
  .rdw-editor-toolbar {
    margin-bottom: 0;
  }
  .rdw-editor-main {
    padding: 10px;
    min-height: 200px;
    border-color: #eee !important;
    box-shadow: none !important;
    color: rgb(51, 65, 85);
    background: #eee;
  }
}
.login {
  .nxl-container {
    margin-left: 0;
    top: 0;
    transition: all 0s ease;
  }
}

.column-label {
  white-space: nowrap;
}

.field-content-area {
  display: flex;
  justify-content: flex-end;

  .avatar-image {
    display: none;
  }
}

.logo-section {
  .field-content-area {
    height: 200px;
    width: 100%;
  }
}

.page-header {
  &.invite {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
  }
}

.about-section {
  text-align: left;
  align-items: center;
  justify-content: flex-start !important;
  justify-items: flex-start;
}

.description-section,
.about-section {
  align-self: flex-start;
  width: 100%;

  .form-group {
    align-self: flex-start;
    align-items: flex-start;
  }
}

.column-header {
  padding: 10px 20px 0;

  .column-header-title {
    //font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #fff;
    border-bottom: 2px solid rgba(120, 199, 235, 0.1);
    padding-bottom: 10px;
    text-align: left;
  }
}

.title-box {
  padding: 7px 15px;
  background: #263155;
  font-size: 15px;
  font-weight: bold;
}

.property-distributions-box {
  padding: 15px;

  &:nth-child(odd) {
    background-color: #243150;
  }

  &:nth-child(even) {
    background-color: #1b2846;
  }
}

.management-bg {
  .content-header {
    padding: 0 20px;
  }
}

.management-bg .card-header.management-header {
  margin-top: 0;

  button {
    background: transparent;
    border: none;
    margin-right: 5px;
    outline: none;

    svg {
      transform: rotate(270deg);
      transition: all 0.25s;

      g {
        fill: #fff;
      }
    }

    &.active svg {
      transform: rotate(360deg);
      transition: all 0.25s;

      g {
        //fill:rgb(79, 204, 255);
      }
    }
  }
}

.about-section,
.logo-section,
.description-section {
  .form-group {
    flex-direction: column;
    text-align: left;
    align-items: left;
  }

  textarea {
    width: 100%;
    min-height: 201px;
    margin-top: 10px;
  }

  p {
    margin-top: 10px !important;
  }

  label {
    display: none;
  }
}

.description-section {
  label {
    display: none;
  }
}

.hide-table {
  .table {
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}

.entity-profile {
  .table {
    padding-left: 0;
    padding-right: 0;
  }
}

.cap-event-table-header {
  background: #e2e0e0;
  padding-top: 0;
  padding: 10px 20px;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1410px;
  }
}
