.gap-3 {
  gap: 1rem !important;
}

.rdt_TableCell {
  //max-width: 100% !important;
}

.has-child-table {
  .rdt_TableRow,
  .rdt_TableHeadRow {
    justify-content: inherit;
  }
}

.single-table.no-data {
  .rdt_TableRow,
  .rdt_TableHeadRow {
    justify-content: space-between;
  }

  .rdt_TableCell,
  .rdt_TableCol {
    max-width: 100%;

    p {
      margin-bottom: 0;
    }
  }
}

.selected-tr {
  background: #edf9ff !important;

  &:hover {
    td {
      background: #edf9ff !important
  ;
    }
  }
}

.d-contents {
  display: contents;

  tr {
    background: #f6fcff;
  }
}

.rotate-180 {
  rotate: 180deg;
}

.rotate-270 {
  rotate: 270deg;
}

.card {
  border-radius: 10px;
  height: calc(100% - 30px);
  padding-top: 0px;
  border: 1px solid #e5e7eb !important;

  .card-header > * {
    margin: 0;
  }

  .card-header {
    min-height: 62px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 1rem 1.5rem;

    .card-title {
      margin-bottom: 0;
      color: #283c50;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .rdt_TableCol,
  .rdt_TableHead {
    height: 42px;
    max-height: 42px;
  }

  [role="columnheader"] {
    color: #334155 !important;
    /* padding: 8px 15px; */
    color: #283c50;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .rdt_TableRow {
    border-bottom: 1px rgba(40, 41, 61, 0.08) solid !important;

    .edit-box {
      padding: 0 5px;
    }

    button {
      background: transparent !important;
    }
  }

  .rdt_TableHead {
    border-bottom: 1px solid #e5e7eb;
  }

  .rdt_ExpanderRow {
    background: transparent !important;
    padding-left: 0;

    .card {
      border-radius: 0;
      margin-bottom: 0;
    }

    .table {
      background: transparent !important;
      padding-left: 0;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.08) inset;
      -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.08) inset;
      -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.08) inset;
    }

    .rdt_TableCol:first-child,
    .rdt_TableCell:first-child {
      margin-left: 48px;
    }
  }
}

.divTable {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.headRow {
  display: flex;
  justify-content: space-around;
  text-align: left;
  background: transparent;
  font-weight: bold;
}

.tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 400px;
  overflow: auto;
}

.tr {
  width: 100%;
  text-align: left;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
  border-bottom: 1px #eee solid;
}

.th {
  width: 100%;
  text-align: left;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
  border-bottom: 1px #eee solid;
}

.td {
  width: 25%;
  text-align: left;
  padding: 15px 15px;
  vertical-align: middle;

  .edit-box {
    max-width: 90%;
  }
}

.headRow .td {
  padding: 0 10px;
}

.tfoot {
  background: #eee;
  padding: 10px 10px;
  font-weight: bold;
  margin-top: 13px;
}

.table-responsive .table .tr .td:first-child {
  padding-left: 30px;
}

.table-responsive {
  td:last-child,
  th:last-child {
    width: 100%;
  }

  th {
    text-transform: capitalize !important;
  }
}

.localized-table {
  .td {
    padding: 0;
  }

  .tr {
    align-items: center;
  }
}

.table-responsive {
  .table > :not(caption) > * > * {
    border-bottom-width: 0px;
  }

  tbody {
    tr {
      border-bottom-width: 1px;
    }
  }
}

.financial-options {
  max-width: 220px;
  width: 100%;
}
