.contact-name {
	flex-direction: column;
	align-items: flex-start;
}

.offcanvas-body {
	.rdw-editor-main {
		min-height: 350px;
		padding: 0 20px;
	}
}

li.custom-side-header {
	width: 300px;
	border-right: 1px #eee solid;
	.content-sidebar-header {
		display: flex;
		height: 75px;
		padding: 25px 30px;
		border-bottom: 1px solid #e5e7eb;
		display: flex;
		align-items: center;
		width: 100%;
	}
}

.contact-sidebar,
.document-sidebar {
	.no-header {
		padding-top: 66px;
	}
}

.contact {
	.investor-profile {
		padding-top: 66px;
	}
}

.attribute-list {
	width: 100%;
	white-space: normal;
	flex-wrap: wrap;
	display: flex;
	.badge {
		max-width: 100%;
		white-space: break-spaces;
		text-align: left;
		line-height: 130%;
	}
}

.sidebar-top-filter {
	.dropdown-menu {
		position: absolute;
		right: 0;
		.custom-control-input {
			left: 10px;
			opacity: 0;
			z-index: -1;
			width: 1.25rem;
			height: 1.375rem;
			position: absolute;
		}
	}
}

[width="0px"] {
	display: none !important;
}

[data-column-id="7"] {
	justify-content: flex-end;
}
