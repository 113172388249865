.table {
  margin-bottom: 0;
}

.bold {
  font-weight: bold;
}

.success {
  color: green;
}

.form-group {
  margin-bottom: 6px;

  .select-card__control,
  .select-box__control {
    min-height: 46.27px;
    color: rgb(51, 65, 85);
    background: #eee;
    border-color: #eee !important;
  }
}

.modal,
.dropzone-area {
  .select-box {
    .select-box__single-value {
      font-size: 0.845rem;
    }
  }
}

html.app-skin-dark {
  .text-link {
    color: #fff;
  }

  .select-box__control--menu-is-open,
  .select-box__control--is-focused,
  input.form-control {
    border-color: #24456c;
    color: #fff;
    background: #24456c;

    .select-box__single-value,
    .select-box__single-value {
      color: #fff;
    }
  }

  .table .rdt_ExpanderRow {
    .select-box__control--menu-is-open,
    .select-box__control--is-focused,
    input.form-control {
      border-color: #24456c;
      color: #fff;
      background: #24456c;

      .select-box__single-value,
      .select-box__single-value {
        color: #fff;
      }
    }
  }

  color: #7792b1;
  background-color: #112239;

  @media (max-width: 767px) {
    .nxl-navbar {
      &.active {
        background: #0f172a;
        border: 1px solid #0f2139;
      }
    }
  }

  .doc-message button i {
    color: #fff !important;
  }

  .nav-tabs-wrapper .nav-tabs-custom-style {
    background: #0f172a;
    border: 1px solid #0f2139;
  }

  .rdt_Pagination {
    color: #7792b1;
  }

  .search-form.active {
    background: transparent;
  }

  .modal .form-group .select-box__control,
  .modal .form-group .select-card__control {
    background: transparent;
  }

  .tfoot {
    background: transparent;
  }

  .custom-file,
  .custom-select,
  .form-control,
  .form-select,
  input,
  textarea {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .apexcharts-legend-text,
  .text-muted,
  .text-muted time,
  .sales-pipeline-tabs span,
  .breadcrumb-item.active {
    color: #fff !important;
  }

  .card .rdt_TableRow button {
    color: #fff !important;
    border-color: #fff !important;
  }

  .card .rdt_TableCol,
  .card .rdt_TableHead {
    background: transparent !important;

    [role="columnheader"] {
      color: #fff !important;
    }
  }

  .empty-data-message {
    a {
      &:hover {
        color: #fff;
      }
    }
  }

  .large-body {
    padding: 0 30px;

    p,
    span {
      color: #8a99b5;
    }
  }

  .edit-box::placeholder {
    opacity: 0.9;
    color: #cee0ee;
  }

  a:active,
  a:focus,
  a:hover {
    color: #fff;
  }

  .rdt_ExpanderRow {
    background: #101e33;
  }

  .table {
    // background: #212529;
  }

  .main-header li.user-header {
    background-color: #232d4c;
  }

  .management-bg {
    background: #102139;
  }

  .main-header .logo-box {
    background: transparent;
  }

  .small-stat-box .title span {
    color: #fff;
  }

  .new-border-bottom {
    border-bottom: 2px transparent solid;
    margin-bottom: 25px;
  }

  .card-header .card-title {
    color: #858f9e;
  }

  .rdt_TableRow .aliceblue {
    background-color: transparent;
    color: #f3f4f6;
  }

  .rdt_TableRow .orangered {
    background-color: orangered;
    color: #000;
  }

  .rdt_TableRow .lightgreen {
    background-color: lightgreen;
    color: #000;
  }

  .card {
    background: transparent;
    border: 2px #272e48 solid;
  }

  .moon.nxl-link svg path {
    fill: #8a99b5;
    stroke: transparent;
  }

  .nxl-link svg path {
    fill: #fff;
  }
}

.large-body p span {
  background-color: transparent !important;
}

.app-skin-light {
  .text-link {
    color: #374aab;
  }

  .select-box__menu {
    background: #fff;
    z-index: 99;
  }

  @media (max-width: 767px) {
    .nxl-navbar {
      &.active {
        background: #fff;
        border: 1px solid #0f2139;
      }
    }
  }

  .icon-checkmark circle {
    fill: #3dc480;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform 200ms cubic-bezier(0.22, 0.96, 0.38, 0.98);
  }

  .icon-checkmark path {
    fill: #3dc480;
    transition: stroke-dashoffset 350ms ease;
    transition-delay: 100ms;
    stroke: #fff;
  }

  .input-button {
    padding: 2px 15px;
    border-color: #e9e9e9 !important;
    color: #000;
    background: #ebebeb;
    border: 1px solid;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
  }

  color: rgba(51, 65, 85, 1);

  .rdt_Pagination {
    color: rgba(51, 65, 85, 1);
  }

  .edit-box.has-value::placeholder {
    color: #334155 !important;
    font-size: 14px;
  }

  .nxl-link svg path {
    fill: #334155;
  }

  .select-box__control .select-box__single-value {
    color: #334155 !important;
  }

  .property-box:hover .card {
    border-color: #666;
  }

  .property-box .row .col-6 span {
    color: #fff;
  }

  .property-box.property-box-main {
    &.active .card {
      span,
      h4 {
        color: #666;
      }
    }
  }

  &.invite .container-full .content-header {
    position: relative;
    top: 0;
    border-top: 0;
    width: 100%;
  }

  .media.message-item.active,
  .media.message-item.active:hover {
    background-color: #dadada;
  }

  .media-list-hover
    > .media:not(.media-list-header):not(.media-list-footer):hover,
  .media-list-hover .media-list-body > .media:hover {
    background-color: #e3e3e3;
    color: #475f7b;
  }

  .media-list-hover
    > .media:not(.media-list-header):not(.media-list-footer):hover
    a:not(.btn):not(.avatar),
  .media-list-hover .media-list-body > .media:hover a:not(.btn):not(.avatar) {
    color: #475f7b;
  }

  .large-body {
    padding: 0 30px;

    p,
    span {
      color: rgba(51, 65, 85, 1);
    }
  }

  .edit-box::placeholder {
    opacity: 0.9;
    color: #666;
  }

  .investment-docs a {
    border-color: #eee;

    svg g {
      fill: #717171;
    }
  }

  .select-box__value-container * {
    color: #334155;
  }

  .main-sidebar {
    border-right: 1px solid $lightBorder;
    background-color: $lightBG;
  }

  #modalMaster .form-control {
    border-color: #d8d8d8;
    color: rgba(51, 65, 85, 1);
    background: #eee;
  }

  .sidebar {
    border-top: 1px solid rgba(40, 41, 61, 0.08);
  }

  .content-header .page-title {
    color: rgba(51, 65, 85, 1);
  }

  .container-full .content-header {
    border-bottom: 1px solid $lightBorder;
    border-top: 1px solid $lightBorder;
    margin-bottom: 25px;
    position: fixed;
    background: $lightBoxBG;
    padding: 0px 50px 0px;
    width: calc(100vw - 48px);
    right: 0;
  }

  .container-full {
    border: none;
  }

  .card-header.management-header {
    margin-bottom: 0;
    border-bottom: 1px $lightBorder solid;
  }

  .custom-option {
    background: #e9ecef;
    color: #305c91;

    &.selected {
      color: #ffffff;
      background-color: #305c91;
    }

    &:hover {
      color: #305c91;
      background-color: #fff;
    }
  }

  .management-light-bg {
    background: #f3f4f6;
  }

  .table {
    background: #fff;
  }

  .management-bg {
    background: #f3f4f6;
  }

  .main-header .logo-box {
    background: #fff;
  }

  .small-stat-box .title span {
    color: rgba(51, 65, 85, 1);
  }

  .new-border-bottom {
    border-bottom: 2px transparent solid;
    margin-bottom: 25px;
  }

  .rdw-editor-toolbar,
  .lower-btn-area,
  .rdw-editor-main {
    background-color: transparent;
  }

  .card-header .card-title {
    color: rgba(51, 65, 85, 1);
  }

  .table .card-title {
    font-weight: 400;
  }

  &.theme-primary .btn-primary {
    color: rgba(51, 65, 85, 1);
    background-color: $lightBorder;
    border-color: $lightBorder;

    &:active,
    &:focus,
    &:active {
      color: rgba(51, 65, 85, 1);
      background-color: $lightBorder;
      border-color: $lightBorder;
    }

    &.btn-warning {
      background-color: #fdac41;
      border-color: #fdac41;
      color: #ffffff;
    }

    &.btn-danger {
      background-color: #ff5b5c;
      border-color: #ff5b5c;
      color: #ffffff;
    }

    &:hover {
      background-color: #0a0e17;
      border-color: #0a0e17;
      color: #ffffff;
    }
  }

  &.theme-primary.fixed .main-header,
  .theme-primary .main-header {
    background: #fff;
  }

  .rdt_TableCell,
  .rdt_TableCell time {
    color: rgba(51, 65, 85, 1);
  }

  .rdt_TableCol {
    background-color: transparent;
  }

  .rdt_TableHeadRow {
    background: transparent;
  }

  .card-header.management-header {
    svg {
      path {
        fill: rgba(51, 65, 85, 1);
      }
    }
  }

  .rdt_TableRow button {
    background: $lightBorder;

    path:first-child {
      fill: rgba(51, 65, 85, 1);
    }
  }

  .rdt_TableRow .aliceblue {
    background-color: #f3f4f6;
    color: rgba(51, 65, 85, 1);
  }

  .rdt_TableRow .orangered {
    background-color: orangered;
    color: rgba(51, 65, 85, 1);
  }

  .rdt_TableRow .lightgreen {
    background-color: lightgreen;
    color: rgba(51, 65, 85, 1);
  }

  &.theme-primary .sidebar-menu > li.active {
    background-color: rgba(160, 174, 192, 0.2);
  }

  svg {
    g {
      fill: #fff;
    }
  }

  .active {
    svg {
      g {
        fill: #eee;
      }
    }
  }

  .card {
    background: #fff;
    border: none;
  }

  .custom-select__trigger {
    background: #fff;
    color: rgba(51, 65, 85, 1);
    border: 1px solid $lightBorder;
  }

  .rdt_TableRow {
    border-bottom: 1px $lightBorder solid;
  }

  .stat-row:after {
    background-color: $lightBorder;
  }

  .has-footer .rdt_TableBody .rdt_TableRow:last-child,
  .localized-table th {
    background: #eee;
  }

  .localized-table tr td {
    border-bottom: 1px $lightBorder solid;
  }

  .property-box,
  .property-box-small {
    color: #fff;
  }

  .property-box-main {
    .card {
      color: rgba(51, 65, 85, 1);
    }
  }

  .edit-box {
    border-bottom: 1px solid #d8d8d8;
    border: solid 0.7px #d8d8d8;
    background: #eee;
    outline: none;
    color: rgba(51, 65, 85, 1);
  }

  .editable-column-box .select-box__control {
    border-color: #d8d8d8;
    color: rgba(51, 65, 85, 1);
    border: solid 0.7px #d8d8d8;
    background: #eee;
  }

  .moon.nxl-link svg path {
    fill: rgba(51, 65, 85, 1);
    stroke: transparent;
  }

  .sidebar a {
    color: rgba(51, 65, 85, 1);
  }

  .small-btn {
    border-radius: 4px;
    border: solid 0.7px #d8d8d8;
    background: #eee;
    color: rgba(51, 65, 85, 1);
  }

  .rdt_TableCell a:hover {
    color: rgba(51, 65, 85, 1);
  }

  .dropzone-area .dropzone,
  .modal .dropzone {
    height: 370px;
    display: flex;
    border: 2px solid;
    background: $lightBorder;
    border-color: #eee;
  }

  .column-header .column-header-title {
    color: rgba(51, 65, 85, 1);
    border-color: $lightBorder;
  }

  .select-box__control--menu-is-open,
  .select-box__control--is-focused,
  input.form-control,
  .css-yk16xz-control {
    border-color: #eee !important;
    box-shadow: none !important;
    color: rgba(51, 65, 85, 1);
    background: #eee;
  }

  .messages {
    .select-box__control--menu-is-open,
    .select-box__control--is-focused,
    input.form-control,
    .css-yk16xz-control {
      border-color: transparent !important;
      box-shadow: none !important;
      color: rgba(51, 65, 85, 1);
      background: transparent;
    }
  }

  #modalMaster .modal-content {
    background-color: #fff;
  }

  #modalMaster h3 {
    color: rgba(51, 65, 85, 1);
  }

  .create-entity {
    color: rgba(51, 65, 85, 1);
  }

  .property-box.active .card {
    background: $lightBorder;

    .bottom,
    h4 {
      color: #fff;
    }
  }

  time {
    color: #737373;
  }

  [data-overlay="3"]::before {
    opacity: 0;
  }

  [role="columnheader"] {
    color: rgba(51, 65, 85, 1);
  }

  .title-box {
    padding: 7px 15px;
    background: $lightBorder;
  }

  .property-distributions-box:nth-child(even) {
    background-color: #fff;
  }

  .property-distributions-box:nth-child(odd) {
    background-color: #f6f6f6;
  }

  .css-1uccc91-singleValue {
    color: rgba(51, 65, 85, 1);
  }

  .key-items .key-item {
    color: rgba(51, 65, 85, 1);
  }

  .media-body {
    span,
    p,
    h6 {
      background: transparent;
    }
  }

  .apexcharts-xaxis-label tspan,
  .apexcharts-text tspan,
  .apexcharts-legend-text {
    fill: rgba(51, 65, 85, 1);
    color: rgba(51, 65, 85, 1);
  }

  .slick-next:before,
  .slick-prev:before {
    color: rgba(51, 65, 85, 1);
  }

  .rdt_ExpanderRow .rdt_TableBody .rdt_TableCell,
  .rdt_ExpanderRow .rdt_TableBody .rdt_TableCell time {
    color: rgba(51, 65, 85, 1);
  }

  .nxl-link svg path {
    fill: rgba(51, 65, 85, 1);
  }

  &.theme-primary .auth-box .btn-primary {
    background-color: #71d2fc;
    color: #1a233a;
    border-color: #71d2fc;

    &:hover {
      background-color: #1a233a;
      border-color: #1a233a;
      color: #fff;
    }
  }

  .rdw-editor-wrapper {
    border: 1px solid #d0cdcd;
  }
}
