.accordion .accordion-item .accordion-header .accordion-button:after {
  margin-left: 20px;
}

.tabcontrol,
.wizard {
  display: block;
  width: 100%;
}
.tabcontrol button,
.wizard a {
  outline: 0;
}
.tabcontrol ul,
.wizard ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
.tabcontrol ul > li,
.wizard ul > li {
  display: block;
  padding: 0;
}
.tabcontrol > .steps .current-info,
.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}
.tabcontrol > .content > .title,
.wizard > .content > .title {
  position: absolute;
  left: -999em;
}
.wizard > .steps {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.wizard.vertical > .steps {
  display: inline;
  float: left;
}
.wizard > .steps .number {
  font-size: 1.429em;
}
.wizard > .actions > ul > li,
.wizard > .steps > ul > li {
  float: left;
  display: grid;
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.wizard > .steps button {
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;
}

.wizard > .steps button,
.wizard > .steps button:active,
.wizard > .steps button:hover {
  margin: 0 0;
  padding: 30px 25px 25px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.wizard > .steps .disabled button,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover {
  cursor: default;
}
.wizard > .steps .current button,
.wizard > .steps .current button:active,
.wizard > .steps .current button:hover {
  cursor: default;
  color: #3454d1;
  border-bottom: 3px solid #3454d1;
}
.wizard > .steps .done button,
.wizard > .steps .done a:active,
.wizard > .steps .done a:hover {
  color: #283c50;
}
.wizard > .steps .error button,
.wizard > .steps .error a:active,
.wizard > .steps .error a:hover {
  color: #d13b4c;
  cursor: default;
  border-bottom: 3px solid #d13b4c;
}
.wizard > .content {
  display: block;
  min-height: auto;
  position: relative;
  width: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}
.wizard > .content > .body {
  float: left;
  width: 100%;
  height: 100%;
  padding: 25px 25px;
}
.wizard > .content > .body ul {
  list-style: disc !important;
}
.wizard > .content > .body ul > li {
  display: list-item;
}
.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .content > .body input {
  display: block;
  border: 1px solid #e5e7eb;
}
.wizard > .content > .body input[type="checkbox"] {
  display: inline-block;
}
.wizard > .content > .body input.error,
.wizard > .content > .body input[type="checkbox"].error,
.wizard > .content > .body textarea.error {
  background: #fbe3e4;
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}
.wizard > .content > .body input.valid,
.wizard > .content > .body input[type="checkbox"].valid,
.wizard > .content > .body textarea.valid {
  border: 1px solid #84daaa;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}
.wizard > .content > .body label.error {
  color: #d13b4c;
  font-size: 12px;
  display: block;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  padding: 10px 15px 10px 40px;
  background-color: #ffeff2;
  border: 1px dashed #ffced3;
  transition: all 0.3s ease;
}
.wizard > .content > .body label.error:before {
  position: absolute;
  content: "\e904";
  left: 15px;
  font-family: feather;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}
.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}
.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}
.wizard > .actions > ul > li {
  margin: 0 0.5em;
}
.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}
.wizard > .actions button,
.wizard > .actions button:active,
.wizard > .actions button:hover {
  color: #fff !important;
  border-color: #3454d1;
  background-color: #3454d1;
  display: flex;
  padding: 12px 16px;
  font-size: 10px;
  line-height: normal;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 3px;
  transition: all 0.3s ease;
}
.wizard > .actions .disabled button,
.wizard > .actions .disabled button:active,
.wizard > .actions .disabled button:hover {
  color: #7587a7 !important;
  background: #fff;
  border: 1px solid #e9ebee;
}
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}
.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}
.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}
.tabcontrol > .steps > ul > li > button {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}
.tabcontrol > .steps > ul > li > button:hover {
  text-decoration: none;
}
.tabcontrol > .steps > ul > li.current > button {
  padding: 15px 30px 10px 30px;
}
.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}
.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.tabcontrol > .content > .body ul {
  list-style: disc !important;
}
.tabcontrol > .content > .body ul > li {
  display: list-item;
}
/*# sourceMappingURL=jquery.steps.min.css.map */

.step-body {
  .select-investor p {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: left;
    font-weight: bold;
  }

  .investor-btns .ContactSearchBox {
    max-width: 100%;
  }

  .select-box__control {
    min-height: 52px;
  }
  .form-group .select-box__control {
    min-height: 44px;
  }
}

.text-link {
  background: transparent;
  border: none;
  font-weight: bold;
}

.form-switch.form-switch-sm .form-check-input {
  height: 34px;
  width: 70px;
  border-radius: 2rem;
  top: 7px;
}

.wizard > .actions > ul > li,
.wizard > .steps > ul > li {
  float: left;
  flex: 0 0;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  border-top: none;
  border-right: 1px solid #e5e7eb;
}

.wizard > .actions > ul > li,
.wizard > .steps > ul > li:last-child {
  border-right: none;
}

.investor-btns .ContactSearchBox {
  width: 100%;
  max-width: 100%;
  text-align: left;
}

.wizard > .content.capital_calls_investors,
.wizard > .content.distributions_properties,
.wizard > .content.distributions_investors {
  max-width: 100%;
}

.modal-body {
  .sd-row__panel,
  .sd-question {
    border-radius: 20px;
  }

  .sd-title.sd-element__title {
    color: rgb(51, 65, 85);
    font-family: "Source Sans Pro", sans-serif !important;
  }

  .sd-element--with-frame.sd-question--title-top
    > .sd-question__erbox--above-question,
  .sd-element--complex.sd-element--with-frame > .sd-element__erbox {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .sd-row__panel .sd-question {
    border-radius: 0;
  }

  .sd-input {
    text-align: left !important;
    box-shadow: none !important;
    border: 1px #eee solid !important;
  }

  .sd-multipletext__item-title {
    display: flex;
  }

  .sd-question__header--location--left {
    width: 40%;
  }

  .sd-title.sd-container-modern__title,
  .sd-element__title .sd-element__num {
    display: none !important;
  }

  .sd-progress-buttons__list li:after {
    margin: 0 !important;
    width: 30px !important;
    height: 30px !important;
    border-width: 3px !important;
    display: flex !important;
    align-items: center !important;
  }

  .sd-root-modern.sd-root-modern--full-container {
    background: #f2f2f275 !important;
  }
}

.modal-body .sd-question__header--location-top {
  padding-bottom: 5px;
}

.modal-body textarea.sd-input {
  height: 301px;
}

.modal-body {
  .sd-input.sd-input--disabled,
  .sd-input.sd-input--disabled::placeholder {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 1;
    background: transparent;
    border: 0 !important;
  }

  .sd-table__cell {
    text-align: left;
  }
}

.dynamic-survey {
  .plaid-button.p-4 {
    padding: 0 !important;
  }
}

.table .fs-12 {
  font-size: 12px !important;
}

.inline-field {
  padding: 0;
  border: none;
  width: 100px !important;
  border-bottom: 1px #3454d1 solid;
  width: auto;
  border-radius: 0 !important;
}

.inline-text-field {
  padding: 0;
  border: none;
  width: 100px !important;
  border-bottom: 1px transparent solid;
  width: auto;
}

.create-report-modal {
  .sd-body {
    max-width: 100% !important;
  }
}

[data-key^="parent-panel"] {
  max-width: 100% !important;
  width: 100% !important;

  .sd-panel__content {
    display: flex;
    gap: 2rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .sd-element {
    box-shadow: none;
  }

  .sd-row {
    margin-top: 0;
  }

  .sd-panel {
    background: transparent;
    padding: 0;
    border: none;

    [data-key^="investors-select"],
    [data-key^="investment-select"],
    [data-key^="properties-select"],
    [data-key^="entity-select"] {
      padding: 0;
      border-radius: 20px !important;

      .sd-element {
        box-shadow: var(
          --sjs-shadow-small,
          0px 1px 2px 0px rgba(0, 0, 0, 0.15)
        );
        border-radius: 20px !important;
      }
      .sd-panel {
        border: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
      }
    }
  }
}

.sd-tagbox {
  min-height: 50px !important;
  padding-left: 15px !important;
}

[data-key^="investors-select"],
[data-key^="investment-select"],
[data-key^="properties-select"],
[data-key^="entity-select"] {
  .sd-question {
    padding: 0;
    overflow: hidden;

    .inner-content {
      display: flex;
      flex-direction: column;
      background: #fff;
    }

    .sd-question__content {
      height: 100%;
    }

    .card {
      box-shadow: none !important;
      border: none !important;
    }

    .sd-element__header {
      padding-top: var(--sd-base-vertical-padding);
      padding-bottom: 17px;
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .sd-element__header--location-top::after {
      bottom: calc(-1 * var(--sd-base-vertical-padding));
      left: calc(-1 * var(--sd-base-padding));
      width: calc(100% + 2 * var(--sd-base-padding));
      content: " ";
      display: block;
      position: relative;
      height: 1px;
      background: var(--sjs-border-light, var(--border-light, #eaeaea));
      bottom: 0;
    }

    .sd-panel__description {
      padding-bottom: 32px;
    }

    .custom-checkbox {
      height: 20px;
    }

    .custom-control-label {
      left: 8px;
    }

    .card-body {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}

.sd-body.sd-body--responsive .sd-body__navigation {
  justify-content: flex-end;
}

.items-details-footer {
  .rdw-editor-main {
    background-color: #fff !important;
    min-height: 300px;
    padding: 11px;
  }

  .rdw-editor-toolbar {
    background-color: #fff !important;
    margin-bottom: 0;
  }
}
