.slick-slide {
	.property-info,
	.investment-card {
		img {
			display: block;
			height: 230px !important;
			object-fit: cover;
		}
	}
}
