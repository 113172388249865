@media (min-width: 1199.99px) {
	.nxl-container {
		.app-sidebar-close-trigger,
		.mobile-nav-btn {
			display: none !important;
		}
	}
	.nav-tabs-wrapper {
		width: auto;
		overflow-y: hidden;
		overflow-x: auto;
		overflow-y: hidden;
		//max-width: calc(100vw - 320px);
	}
}

#tabMenu {
	width: calc(100vw - 320px);
}

@media (max-width: 1199.98px) {
	.nxl-container
		.nxl-content
		.main-content
		.content-sidebar.app-sidebar-open {
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease;
		transform: translateX(0);
		box-shadow: 0 10px 25px rgba(40, 60, 80, 0.15);
	}
}

@media (min-width: 769px) {
	.nav-tabs-wrapper {
		width: auto;
		overflow-y: hidden;
		overflow-x: auto;
	}

	.nav-tabs {
		display: flex;
		flex-direction: column;
	}

	.nav-tabs-wrapper .nav-tabs-custom-style {
		border-bottom: none;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	.nxl-header .header-wrapper ul .management-link {
		border-left: none;
		padding-left: 0;
	}

	.pdf-main-box {
		max-width: 100% !important;
	}

	.nxl-navbar {
		height: 0;
		padding: 0;
		overflow: hidden;
		border: none;
		position: fixed;

		&.active {
			position: fixed;
			height: auto;
			flex-direction: column;
			top: 110px;
			width: 100%;
			left: 0;
			text-align: left;
			padding: 0 40px;
			height: auto;
			top: 80px !important;
			padding: 30px;
			left: 6px !important;
			border: 1px solid #e5e7eb;
			box-shadow: 0 10px 24px 0 rgba(62, 57, 107, 0.18);
			animation: 0.4s ease-in-out 0s normal forwards 1 fadein;
			margin-bottom: 0;
			display: inline-flex;
			list-style: none;
			gap: 25px;
			min-width: 20rem;
			max-width: 485px;
			transition: all 0.3s ease-in-out;
			border-radius: 5px;
		}
	}

	.col-3 {
		flex: 0 0 auto;
		width: 100%;
	}

	.description-section {
		.field-content-area {
			justify-content: flex-start;
		}
	}

	.field-content-area {
		margin-top: 0 !important;
	}

	.editable-column-card {
		padding-bottom: 0 !important;
	}

	.content-column {
		display: flex;
	}

	.m-center {
		justify-content: center;
	}

	.m-column {
		flex-direction: column;
	}

	.no-mlr-m {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.nxl-container .nxl-content .main-content .content-area {
		height: auto;
	}

	.nxl-container .nxl-content .main-content .content-area .content-area-body {
		padding: 30px 30px;
		max-height: 9999999px;
	}

	.nav-wrapper {
		z-index: 99999;
		position: relative;
	}

	.nav-tabs-wrapper .nav-tabs-custom-style .nav-item .nav-link {
		border-right: #e3e2e2 1px solid;
		width: 100%;
		text-align: center;
		justify-content: center;
	}

	.img-group {
		max-width: 100px;
		flex-wrap: wrap;
	}

	.sticky,
	.page-header {
		position: relative;
		top: 0;
		width: 100%;
		left: 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.tab-menu {
		top: 80px;
		padding: 0 !important;
	}

	.tab-trigger {
		height: 70.5px;

		.page-content-left-open-toggle {
			font-size: 16px;
			text-transform: capitalize;
		}
	}

	.mobile-tabs-nav {
		.nav-tabs-wrapper {
			height: 0;
			overflow: hidden;
		}

		&.show {
			.nav-tabs-wrapper {
				height: auto;
			}
		}
	}

	.nav-tabs-wrapper .nav-tabs-custom-style {
		display: flex;
		flex-direction: row;
		overflow: visible;
		width: 100%;
		justify-content: center;
		background: aliceblue;
		align-items: center;
		height: auto;
		background: #f1f1f1;

		li {
			width: 100%;
			text-align: center;
			height: auto;

			a {
				border-bottom: #e3e2e2 1px solid;
				border-right: none;
			}
		}
	}
}
