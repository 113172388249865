.marker {
  .popup-close {
    position: absolute;
    z-index: 999;
    right: 0px;
    padding: 6px 10px;
    padding-bottom: 8px;
    border-bottom-left-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}

.popup-close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99999;
  font-size: 26px;
  background: #fff;
  padding: 8px;

  &:hover {

    color: #000;
    cursor: pointer;
    background: #eee;
  }
}

.mapbox:hover {
  cursor: pointer;
  height: 60px;
  width: 60px;
  -webkit-transition: height 0.2s ease-out, width 0.2s ease;
  -moz-transition: height 0.2s ease-out, width 0.2s ease;
  -ms-transition: height 0.2s ease-out, width 0.2s ease;
  transition: height 0.2s ease-out, width 0.2s ease;
}

.property-map-listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  flex-direction: column;
  width: 240px;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  margin-bottom: 35px;
  background-color: #fff;
  transition: 0.3s;
  position: relative;
}

.property-map-listing-details {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 13px 15px 3px;
}

.property-map-listing-footer {
  flex-grow: 0;
  padding: 20px 30px;
  background-color: #f9f9f9;
  padding: 0 20px 17px 20px;
  border-radius: 0 0 4px 4px;
  position: relative;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-right: 14px;
      color: #777;
      font-size: 13px;
      line-height: 21px;

      i {
        position: relative;
        top: 1px;
        margin-right: 3px;
        color: #777;
      }
    }
  }
}

.property-map-listing-company {
  margin-bottom: 0;
}

.property-map-listing h3.property-map-listing-title {
  font-size: $listing-heading-font-size;
  line-height: 28px;
}

.property-map-listing-footer .bookmark-icon {
  top: 0;
  right: 25px;
  transform: translateY(-50%) scale(0.96);
}

.property-map-listing .property-map-listing-company-logo {
  img {
    max-width: 100%;
  }
}

.property-map-listing-company {
  color: #1a233a !important;
}

.property-map-listing .property-map-listing-description {
  flex: 1;
  padding-top: 10px;

  h4.job-listing-company {
    font-size: 16px;
    color: #808080;
  }

  h3.job-listing-title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
}

.bidding-value {
  min-height: 27px;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {

  .tasks-list-container.tasks-grid-layout .task-listing .task-listing-details,
  .grid-layout .property-map-listing-details,
  .tasks-list-container.tasks-grid-layout .task-listing-bid {
    flex-basis: 100px;
  }
}

/* IE 11 Fixes - End */

/* Media Queries for Grid Layout */
@media (max-width: 768px) {
  .full-page-content-inner .grid-layout .property-map-listing {
    margin: 0 0 30px 0;
    width: 100%;
  }

  .property-map-listing {
    margin: 0 0 30px 0;
    width: 100%;
  }

  .full-page-content-inner .grid-layout {
    width: 100%;
  }

  .full-page-content-container .simplebar-content {
    overflow-x: hidden !important;
  }

  .full-page-container.with-map .full-page-content-inner .grid-layout .property-map-listing {
    width: 100%;
  }
}
