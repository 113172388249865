.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
}

.custom-select-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  border-width: 0 2px 0 2px;
  border-style: solid;
  border-color: transparent;
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 20px;
  font-size: 14px;
  font-weight: 300;
  color: #bdd1f8;
  height: 40px;
  line-height: 30px;
  background: #272e48;
  cursor: pointer;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-color: transparent;
  margin: 18px 0 0;

  &.wide {
    width: 230px;
  }
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 0px solid #1a233a;
  border-top: 0;
  background: #fff;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
}

.custom-select-dropdown.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 12px 0 12px;
  font-size: 14px;
  font-weight: 300;
  color: #3b3b3b;
  line-height: 45px;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  background-color: #b2b2b2;
}

.custom-option.selected {
  color: #ffffff;
  background-color: #305c91;
}

.arrow {
  position: absolute;
  right: 15px;
  height: 10px;
  width: 15px;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -1px;
  transform: rotate(45deg);
  background-color: #bdd1f8;
}

.arrow::after {
  left: 5px;
  transform: rotate(-45deg);
  background-color: #bdd1f8;
}

.open .arrow::before {
  left: -1px;
  transform: rotate(-45deg);
}

.open .arrow::after {
  left: 5px;
  transform: rotate(45deg);
}


.dropzone {

  .block-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
