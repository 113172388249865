.o-auto {
  overflow: auto !important;
}

.mh-100 {
  max-height: 90% !important;
}

.mh-70 {
  max-height: 70vh;
  min-height: 500px !important;
}

.has-modal {
  overflow: hidden;
}

.pdf-box {
  min-height: 600px;
  height: 100%;

  .pdf-controls {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    button {
      background: transparent;
      border: none;

      &.left {
        color: #fff;
        font-size: 19px;
        top: 1px;
        position: relative;
        margin-right: 10px;
      }

      &.right {
        color: #fff;
        font-size: 19px;
        top: 1px;
        position: relative;
        margin-left: 10px;
      }
    }
  }

  .block-title {
    color: #fff;
    margin: 0;
  }
}

.modal-body {
  .select-box.edit-box {
    margin: 0;
  }
}
.react-pdf__Page.hide {
  display: none;
}

.react-pdf__Page.active {
  display: block;
}

.pdf-box .icon-loading circle {
  fill: #fff;
}

.modal-body .rdw-editor-wrapper .rdw-editor-main {
  min-height: 200px;
}

.modal-body {
  overflow: auto;
  max-height: calc(100vh - 150px);
  height: auto;
  min-height: 450px;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: initial;
}

.react-pdf__Page {
  width: 100%;
  min-width: 0px !important;

  .textLayer,
  .annotationLayer {
    display: none !important;
  }
}

#modalMaster.modal.show .modal-dialog,
.modal-backdrop.show.modal.show .modal-dialog {
  pointer-events: all;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  position: relative;
  top: 0;
  align-items: flex-start;
  overflow: auto;

  &.document-modal {
    margin: 0 auto;
    max-width: 100vw;
    width: 100vw;
    display: flex;
    justify-content: center;

    .block-header {
      width: 100vw;
      background: #102239;
      margin-bottom: 20px;
    }

    .blank-btn,
    i {
      color: #fff;
    }
  }
}

.modal-content {
  &.document {
    overflow: auto;
    max-height: calc(100vh - 140px);
  }

  .card-header {
    display: none;
  }
}

.blank-btn {
  margin: 10px;
  background: transparent;
  border: none;

  &.right {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  .custom-select-wrapper {
    width: 110px;
    margin-right: 10px;

    .custom-select__trigger {
      margin: 0;
    }
  }

  i {
    color: #8a99b5;
  }
}

.pdf-main-box {
  margin: 0px auto;
  max-width: 900px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.25s ease-out;
}

.dynamic-form-box {
  .mb-4 {
    margin-bottom: 0 !important;
  }

  .btn-primary {
    margin: 5px;

    @media (max-width: 768px) {
      margin-right: auto !important;
      margin-left: 0;
    }
  }

  .form-group {
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
    }

    label {
      min-width: 200px;
      font-weight: bold;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    label + div {
      width: 100%;
      margin: 5px;

      .react-datepicker-wrapper {
        input {
          margin: 0;
        }
      }
    }
  }
}
.wizard > .content.capital_calls_investors_confirmation,
.wizard > .content.distributions_investors_confirmation {
  width: 100%;
  max-width: 90%;

  .rdt_TableRow {
    border-bottom: 1px #484b5c solid;
    padding: 0px 10px;
    min-height: 40px !important;
  }
}
.capital_calls_investors,
.distributions_properties,
.distributions_investors {
  .step-body,
  .custom-card-action {
    padding: 0 !important;
  }

  .actions {
    margin-top: 0 !important;
  }

  .footer.total {
    padding: 20px;
    background: #f5f5f5;

    &.bb {
      border-bottom: 1px #d5d4d4 solid;
    }
  }

  .cap-call-investor,
  .step-body .custom-card-action .justify-content-between {
    padding: 0 20px;

    &:first-child {
      padding-top: 20px;
    }
  }

  .stepper-title {
    padding: 0 20px;
  }

  .secondary-fields {
    padding: 0 20px;
  }
}

.avatar-text {
  img {
    object-fit: fill;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.modal-header-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
