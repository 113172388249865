$bodyfont: "Source Sans Pro", sans-serif;
$headingfont: "Source Sans Pro", sans-serif;

/* -------------------------------------------------------------- */
/* Default Colors
----------------------------------------------------------------- */
$primary-color: #2a41e8;
$body-font-color: #666;
$body-heading-color: #333;

$headerBlue: #182f4f;
$headerText: #8a99b5;
$topHeader: #112239;
$body-dark: #112239;
$activeButton: #152b48;
$white: #fff;
$bgColor: #102139;

$lightBG: #fff;
$lightBorder: rgba(40, 41, 61, 0.08);
$lightBoxBG: #fff;
$lightHeader: #eee;

/* -------------------------------------------------------------- */
/* Typography
----------------------------------------------------------------- */
$primary-font: "Source Sans Pro", sans-serif;
$body-font-size: 16px;
$body-line-height: 27px;
$body-sub-line-height: 26px;

$homepage-heading-font-size: 26px;
$single-page-heading-font-size: 20px;
$listing-heading-font-size: 18px;

$global-border-radius: 4px;

/* -------------------------------------------------------------- */
/* Header Settings for Desktop Devices
----------------------------------------------------------------- */
$header-height: 82px;
$navigation-top-offset: 22px;
$logo-height: 42px;

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$p100: 100%;

$sid-bar-w: 50px;
$hed-max: 120px;
$main-hed-nav: 80px;
$default-border-radius: 5px;
$fct-border-radius: 5px;
$border-circle: 50%;
$mini-sid-bar-w: 80px;
$ctrl-sid-bar-w: 345px;
$inn-pg-sid-bar-w: 250px;

// Typography
//
// Font-size, font-weight

$fw-unset: unset;
$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 600;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
$fw-light: 100;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;

$fs-0: 0;
$fs-10: 0.7143rem;
$fs-12: 0.8571rem;
$fs-14: 1rem;
$fs-16: 1.143rem;
$fs-18: 1.286rem;
$fs-20: 1.429rem;
$fs-22: 1.5714285714285714rem;
$fs-24: 1.714rem;
$fs-26: 1.857rem;
$fs-30: 2.143rem;
$fs-32: 2.2857142857142856rem;
$fs-36: 2.5714285714285716rem;
$fs-38: 2.7142857142857144rem;
$fs-40: 2.857rem;
$fs-42: 3rem;
$fs-46: 3.2857142857142856rem;
$fs-48: 3.4285714285714284rem;
$fs-50: 3.571rem;
$fs-60: 4.286rem;
$fs-70: 5rem;
$fs-72: 5.142857142857143rem;
$fs-76: 5.428571428571429rem;
$fs-78: 5.571428571428571rem;
$fs-80: 5.714rem;
$fs-100: 7.142857142857143rem;
$fs-140: 10rem;
$fs-180: 12.857142857142858rem;
$fs-200: 14.285714285714286rem;

/*Social Media Colors*/
$facebook: #3b5998;
$google: #dd4b39;
$twitter: #1da1f2;
$linkedin: #0077b5;
$pinterest: #bd081c;
$git: #6cc644;
$tumblr: #35465c;
$vimeo: #1ab7ea;
$youtube: #ff0000;
$flickr: #ff0084;
$reddit: #ff4500;
$dribbble: #ea4c89;
$skype: #00aff0;
$instagram: #e1306c;
$lastfm: #d51007;
$behance: #1769ff;
$rss: #f26522;
$bitbucket: #205081;
$dropbox: #007ee5;
$foursquare: #2d5be3;
$github: #4078c0;
$vk: #45668e;

/*Theme Colors*/

$dark: #475f7b;
$light: #d5dfea;
$light2: #fafafa;
$light3: #f2f4f4;
$light4: #dfe3e7;
$light5: #bac0c7;
$white: #ffffff;
$black: #000000;
$danger: #ff5b5c;
$warning: #fdac41;
$success: #39da8a;
$primary: #5a8dee;
$info: #00cfdd;
$secondary: #475f7b;
$mildgray: #bcc2c8;

/*Theme Colors For Dark*/
$dark-text: #8a99b5;
$white-text: #dadee1;
$dark2: #566f9e;
$dark3: #272e48;
$dark-title: #bdd1f8;

$colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "white": $white,
  "black": $black,
  "light": $light,
);

$gray-100: #f3f6f7;
$gray-200: #eff2f9;
$gray-300: #dbe0ec;
$gray-400: #b6c2e4;
$gray-500: #8997bd;
$gray-600: #7081b9;
$gray-700: #50649c;
$gray-800: #435177;
$gray-900: #1c2d41;

$theme-primary-primary: #1a233a;
$theme-primary-secondary: #475f7b;
$theme-primary-info: #00cfdd;
$theme-primary-success: #39da8a;
$theme-primary-danger: #ff5b5c;
$theme-primary-warning: #fdac41;

$theme-primary-grd: linear-gradient(
  45deg,
  $theme-primary-primary,
  $theme-primary-info
);
$theme-primary-grd-dark: linear-gradient(
  45deg,
  darken($theme-primary-primary, 20%),
  darken($theme-primary-info, 20%)
);

$theme-secondary-primary: #475f7b;
$theme-secondary-secondary: #5a8dee;
$theme-secondary-info: #00cfdd;
$theme-secondary-success: #39da8a;
$theme-secondary-danger: #ff5b5c;
$theme-secondary-warning: #fdac41;

$theme-secondary-grd: linear-gradient(
  45deg,
  $theme-secondary-primary,
  $theme-secondary-info
);
$theme-secondary-grd-dark: linear-gradient(
  45deg,
  darken($theme-secondary-primary, 20%),
  darken($theme-secondary-info, 20%)
);

$theme-info-primary: #00cfdd;
$theme-info-secondary: #475f7b;
$theme-info-info: #5a8dee;
$theme-info-success: #39da8a;
$theme-info-danger: #ff5b5c;
$theme-info-warning: #fdac41;

$theme-info-grd: linear-gradient(45deg, $theme-info-primary, $theme-info-info);
$theme-info-grd-dark: linear-gradient(
  45deg,
  darken($theme-info-primary, 20%),
  darken($theme-info-info, 20%)
);

$theme-success-primary: #39da8a;
$theme-success-secondary: #475f7b;
$theme-success-info: #00cfdd;
$theme-success-success: #5a8dee;
$theme-success-danger: #ff5b5c;
$theme-success-warning: #fdac41;

$theme-success-grd: linear-gradient(
  45deg,
  $theme-success-primary,
  $theme-success-info
);
$theme-success-grd-dark: linear-gradient(
  45deg,
  darken($theme-success-primary, 20%),
  darken($theme-success-info, 20%)
);

$theme-danger-primary: #ff5b5c;
$theme-danger-secondary: #475f7b;
$theme-danger-info: #00cfdd;
$theme-danger-success: #39da8a;
$theme-danger-danger: #5a8dee;
$theme-danger-warning: #fdac41;

$theme-danger-grd: linear-gradient(
  45deg,
  $theme-danger-primary,
  $theme-danger-info
);
$theme-danger-grd-dark: linear-gradient(
  45deg,
  darken($theme-danger-primary, 20%),
  darken($theme-danger-info, 20%)
);

$theme-warning-primary: #fdac41;
$theme-warning-secondary: #475f7b;
$theme-warning-info: #00cfdd;
$theme-warning-success: #39da8a;
$theme-warning-danger: #ff5b5c;
$theme-warning-warning: #5a8dee;

$theme-warning-grd: linear-gradient(
  45deg,
  $theme-warning-primary,
  $theme-warning-info
);
$theme-warning-grd-dark: linear-gradient(
  45deg,
  darken($theme-warning-primary, 20%),
  darken($theme-warning-info, 20%)
);
